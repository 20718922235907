import {_get, _post, _put, clubId} from "@api/doinsport/services/httpService";
import {ALL} from "@/classes/doinsport/Campaign";

const URI = '/campaigns/emails';

export const getEmailCampaigns = (itemsPerPage = 10, page = 1, search = '', status = ALL) => {
  const filter = status === ALL ? '' : '&status=' + status;

  return _get(
    URI +
    '?club.id=' + clubId +
    '&itemsPerPage='+ itemsPerPage +
    '&page=' + page +
    '&search=' + search +
    filter
  );
}

export const getEmailCampaign = (id) => _get(URI + '/' + id);

export const putEmailCampaign = (data) => _put(URI + '/' + data.id, data);

export const postEmailCampaign = (data) => _post(URI , data);
