export const ALL = 'all';
export const PENDING_STATUS = 'pending';
export const DRAFT_STATUS = 'draft';
export const DELIVERED_STATUS = 'delivered';
export const EMAIL_CAMPAIGN = 'email';
export const SMS_CAMPAIGN = 'sms';
export const PUSH_CAMPAIGN = 'push';

export const CAMPAIGNS_STATUSES = [
  ALL,
  DRAFT_STATUS,
  PENDING_STATUS,
  DELIVERED_STATUS
];
