import { hydrate } from "@/utils/form";

export default class EmailCampaign {
    id = null;
    name = null;
    subject = null;
    programmingDate = null;
    content = '';
    club = JSON.parse(localStorage.getItem('selectedClub'));
    clients = [];
    filters = [
        {
            key: "group",
            values: [],
        },
        {
            key: "activity",
            values: [],
        },
        {
            key: "subscriptionPlan",
            values: [],
        }
    ];

    constructor(object, options) {
        if ("undefined" !== typeof options) {
            if(options.serialize) {
                this.serialize(object);
            }
        }
    }

    serialize (object) {
        hydrate(this, object);
    }

}
