<template>
  <div>
    <d-body-top-bar
      v-if="custom !== 'custom'"
      :title="menu"
    >
      <div slot="tree">
        <label
          class="inactive-tree pointer link-area"
          @click="$router.push({ name: 'email_campaign'})"
        >
          {{ $t('views.marketing.content.middle.email-campaign') }}
        </label>
        <label class="inactive-tree ml-1 mr-1">
          /
        </label>
        <label class="bg-doinsport-primary ml-1">
          {{ title }}
        </label>
      </div>
    </d-body-top-bar>
    <validation-observer
      ref="emailObserver"
      v-slot="{ invalid }"
      slim
    >
      <div class="container-fluid">
        <b-row>
          <b-col
            :cols="innerWidth ? '3' : '12'"
          >
            <b-card
              :class="custom ==='custom' ? 'custom-background-color' : ''"
            >
              <email-campaign-form
                :mode="mode"
                :custom="custom"
                :customer="customer"
                :validator="validator"
                :group-list="groupList"
                :activity-list="activityList"
                :recipient-list="recipientList"
                :email-campaign="emailCampaign"
                :custom-sent-option="customSentOption"
                :subscription-plan-list="subscriptionPlanList"
                @on:custom-sent-option:update="onCustomSentOptionUpdate"
              />
            </b-card>
          </b-col>
          <b-col
            :cols="innerWidth ? '9' : '12'"
          >
            <b-card :class="custom ==='custom' ? 'custom-background-color' : ''">
              <b-row class="mb-2">
                <b-col align="left">
                  <label class="settings-title">
                    {{ $t('components.form.marketing.campaign.email-content') }}
                  </label>
                </b-col>
                <b-col v-if="custom === 'default'" align="right">
                  <d-button
                    :class="invalid ? 'disabled' : ''"
                    text="general.actions.draft"
                    class="d-btn-sm font-text-title btn d-btn-default mr-2"
                    @on:button-click="onDraft(invalid)"
                  />
                  <d-button
                    :class="invalid ? 'disabled' : ''"
                    text="general.actions.next-step"
                    class="d-btn-sm font-text-title btn d-btn-danger"
                    @on:button-click="onNextStep(invalid)"
                  />
                </b-col>
                <b-col v-else align="right">
                  <d-button
                    :class="invalid ? 'disabled' : ''"
                    text="general.actions.send"
                    class="d-btn-sm font-text-title btn d-btn-danger mr-2"
                    @on:button-click="onNextStep(invalid)"
                  />
                </b-col>
              </b-row>
              <email-editor
                v-if="emailCampaign"
                :default-content="emailCampaign.content"
                @on:editor-content:update="onEditorContentUpdate"
              />
            </b-card>
          </b-col>
        </b-row>
      </div>
    </validation-observer>
  </div>
</template>
<script>

import EmailCampaign from "@/classes/doinsport/EmailCampaign";
import {
  getEmailCampaign,
  postEmailCampaign,
  putEmailCampaign
} from "@api/doinsport/services/campaign/email-campaign.api";
import {DRAFT_STATUS} from "@/classes/doinsport/Campaign";

export default {
  props: {
    custom: {
      type: String,
      default: 'default'
    },
    customer: {
      type: Object,
      default: () => {
      }
    }
  },
  data: () => ({
    isLoaded: false,
    groupList: [],
    mode: 'create',
    validator: false,
    activityList: [],
    recipientList: [],
    customSentOption: false,
    subscriptionPlanList: [],
    emailCampaign: new EmailCampaign(),
  }),
  components: {
    EmailEditor: () => import('@custom/EmailEditor'),
    EmailCampaignForm: () => import('@form/marketing/CampaignForm')
  },
  computed: {
    innerWidth() {
      return this.$store.getters['layout/getInnerWidth'] > 900;
    },
    title() {
      return this.emailCampaign.id ? this.$t('views.marketing.content.middle.edit-campaign') : this.$t('views.marketing.content.middle.new-campaign');
    },
    menu() {
      return this.emailCampaign.id ? this.$t('views.marketing.content.middle.campaign-edition') : this.$t('views.marketing.content.middle.campaign-creation');
    }
  },
  methods: {
    onCustomSentOptionUpdate(scope) {
      this.customSentOption = scope;
    },
    onEditorContentUpdate(content) {
      this.emailCampaign.content = content;
      this.formatEmailCampaign();
    },
    onNextStep(invalid) {
      if (!invalid) {
        if (this.$route.name === 'marketing_email_new' || this.custom === 'custom') {
          if (this.customSentOption && this.emailCampaign.clients.length === 0) {
            this.$flash(null, this.$t('components.form.marketing.campaign.no-selected-recipents'));
          } else {
            this.checkFilters();
            if (this.custom === 'custom') {
              this.emailCampaign.status = 'pending';
            }
            postEmailCampaign(this.emailCampaign)
              .then(
                (response) => {
                  if (this.custom === 'custom') {
                    this.$emit('on:email:sent');
                  } else {
                    this.goToReportPage(response);
                  }
                }
              )
            ;
          }
        } else {
          this.checkFilters();
          putEmailCampaign(this.emailCampaign)
            .then(
              (response) => {
                this.goToReportPage(response);
              }
            )
          ;
        }
      }
    },
    validateForm() {
      this.validator = !this.validator;
    },
    onDraft(invalid) {
      if (!invalid) {
        if (this.customSentOption && this.emailCampaign.clients.length === 0) {
          this.$flash(null, this.$t('components.form.marketing.campaign.no-selected-recipents'));
        } else {
          this.checkFilters();
          if (null === this.emailCampaign.id) {
            postEmailCampaign(this.emailCampaign).then(
              (response) => {
                this.$router.push({name: 'email_campaign'});
              })
            ;
          } else {
            this.emailCampaign.status = DRAFT_STATUS;

            putEmailCampaign(this.emailCampaign)
              .then((response) => {
                this.$router.push({name: 'email_campaign'});
              })
            ;
          }
        }
      }
    },
    checkFilters() {
      if (this.customSentOption) {
        this.emailCampaign.filters = null;
      } else {
        delete this.emailCampaign.clients;
      }
    },
    loadEmailCampaign() {
      this.isLoaded = false;

      getEmailCampaign(this.$route.params.id)
        .then((response) => {
          this.emailCampaign = response.data;
          this.customSentOption = this.emailCampaign.filters === null;
        })
        .finally(() => {
          this.isLoaded = true;
        })
      ;
    },
    goToReportPage(response) {
      const reportRoute = {
        name: 'campaign_email_report',
        params: {
          id: response.data.id,
        }
      }

      this.$router.push(reportRoute);
    },
    formatEmailCampaign() {
      const centeredClasses = this.emailCampaign.content.replaceAll("class=\"ql-align-center\"", "style=\"text-align: center\"");
      const leftClasses = centeredClasses.replaceAll("class=\"ql-align-left\"", "style=\"text-align: left\"");

      this.emailCampaign.content = leftClasses.replaceAll("class=\"ql-align-right\"", "style=\"text-align: right\"");
    }
  },
  created() {
    if (this.custom === 'custom') {
      this.customSentOption = true;
    }
  },
  mounted() {
    if (this.$route.name === 'marketing_email_update') {
      this.loadEmailCampaign();
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@lazy/_b-card.scss";

/deep/ .card .card-body {
  min-height: 100vh;
}

@media only screen and (max-width: 991px) {
  /deep/ .card .card-body {
    min-height: min-content;
  }
}

.custom-background-color {
  background-color: transparent;
}
</style>
